<template>
  <button
    @click="handleButtonClick"
    class="btn casino-btn casino-main-menu-btn csn-nav-btn csn-nav-btn-main-menu csn-nav-dap-btn"
  >
    <span class="csn-nav-btn-label">{{ t('pay_and_play') }}</span>
  </button>
</template>
<script>
import {
  NAVBAR__PAY_AND_PLAY_BUTTON,
  Module,
  SHOW_MODAL,
  PAY_AND_PLAY_MODAL,
} from '@/constants'
import { commitMutation } from '@/helpers'
export default {
  name: NAVBAR__PAY_AND_PLAY_BUTTON,
  computed: {
    t() {
      return this.$createComponentTranslator(NAVBAR__PAY_AND_PLAY_BUTTON)
    },
  },
  methods: {
    handleButtonClick() {
      commitMutation([Module.MODAL, SHOW_MODAL], {
        component: PAY_AND_PLAY_MODAL,
        isStrict: true,
      })
    },
  },
}
</script>
